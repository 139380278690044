export const messages = {
  en: {
    'app.header.home': 'Home',
    'app.header.profile': 'Profile',
    'app.header.login': 'Login',
    'app.header.register': 'Register',
    'app.header.logout': 'Logout',
    'app.login.title': 'Login',
    'app.login.email': 'Email',
    'app.login.password': 'Password',
    'app.login.submit': 'Login',
    'app.login.emailRequired': 'Email is required',
    'app.login.passwordRequired': 'Password is required',
    'app.login.registerPrompt': "Don't have an account? {registerLink}",
    'app.login.registerLink': 'Register here',
    'app.register.title': 'Register',
    'app.register.username': 'Username',
    'app.register.email': 'Email',
    'app.register.password': 'Password',
    'app.register.confirmPassword': 'Confirm Password',
    'app.register.submit': 'Register',
  },
  es: {
    'app.header.home': 'Inicio',
    'app.header.profile': 'Perfil',
    'app.header.login': 'Iniciar sesión',
    'app.header.register': 'Registrarse',
    'app.header.logout': 'Cerrar sesión',
    'app.login.title': 'Iniciar sesión',
    'app.login.email': 'Correo electrónico',
    'app.login.password': 'Contraseña',
    'app.login.submit': 'Iniciar sesión',
    'app.login.emailRequired': 'El correo electrónico es obligatorio',
    'app.login.passwordRequired': 'La contraseña es obligatoria',
    'app.login.registerPrompt': '¿No tienes una cuenta? {registerLink}',
    'app.login.registerLink': 'Regístrate aquí',
    'app.register.title': 'Registrarse',
    'app.register.username': 'Nombre de usuario',
    'app.register.email': 'Correo electrónico',
    'app.register.password': 'Contraseña',
    'app.register.confirmPassword': 'Confirmar contraseña',
    'app.register.submit': 'Registrarse',
  },
};
